import { useTranslation } from "react-i18next"
import Table, { TableHeader, TableValue } from '@/features/common/table'

import arrowImg from "@/assets/arrow.png"
import arrowTop from "@/assets/arrowTop.png"
import Paging from '@/features/common/paging'

import "./assetsList.css"
import { useAppSelector } from "@/app/hooks"
import { reverseRegulation, selectRegulation } from "../entity/entitySlice"
import { useDispatch } from "react-redux"
import { useState } from "react"

export default () => {
  const { t } = useTranslation()
  
  const regulation = useAppSelector(selectRegulation)

  const [page, setPage] = useState(0)
  const [sort, setSort] = useState(0)
  const dispatch = useDispatch()

  if (!regulation) return null
  
  const header: Array<TableHeader> = [
    {content: t("entities.countries"), clickable: true, icon: sort === 0 ? arrowImg : arrowTop, width: "132px", callback: () => {
      setPage(0)
      dispatch(reverseRegulation())
      setSort(sort === 0 ? 1 : 0)
    }},
    {content: t("entities.name"), width: "207px",},
    {content: t("entities.createdAt"), width: "160px",},
    {content: t("status"), width: "105px",},
    {content: t("entities.watchdog"), width: "353px",},
    {content: t("entities.dueAt"), width: "205px",},
  ]
  const body: Array<Array<TableValue>> = regulation.slice(page * 4, page * 4 + 4).map(i => [
    {content: i.country},
    {content: i.name},
    {content: i.statusStartDate},
    {content: i.regulatoryStatus},
    {content: i.supervisoryAuthority},
    {content: i.statusExpiryDate},
  ])

  const clickButton = (key: number, clickType: number) => {
  }

  return (
    <div className="assets-container">
      <div>
        <p className="assets-title">{t("entities.regulatory")}</p>
        <Table th={header} td={body} clickCallback={clickButton} />
      </div>
      <Paging total={regulation.length} page={page + 1} showCount={4} onChange={(page: number) => setPage(page - 1)} />
    </div>
  )
}