import { useTranslation } from "react-i18next"
import Table, { loadingElement, TableHeader, TableValue } from '@/features/common/table'

import arrowImg from "@/assets/arrow.png"
import arrowTop from "@/assets/arrowTop.png"
import Paging from '@/features/common/paging'

import "./assetsList.css"
import { GetColor } from "../common/dashboard"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { blockchainTranslate, CalcAssetsScore, reverseCoins, selectCoins, setCalcScoreStatus } from "./addressSlice"
import { useState } from "react"
import { AnalysisType } from "./addressAPI"
import { useParams } from "react-router-dom"

export function ColorElm(props: {score: number}) {
  return <span style={{
    width: "45px",
    height: "16px",
    textAlign: "center",
    lineHeight: "16px",
    fontSize: "10px",
    fontWeight: "bold",
    display: "inline-block",
    borderRadius: "24px",
    color: "#ffffff",
    backgroundColor: GetColor(props.score),
  }}>{props.score}</span>
}

export function CoinElm(props: {coinSrc: string, coinName: string}) {
  return <div
    style={{"display": "flex", "alignItems": "center"}}
  >
    <img style={{"width": "20px"}} src={props.coinSrc} alt="" />
    <span style={{fontWeight: 300,
      fontSize: "14px",
      lineHeight: "14px",
      color: "#425466", 
      paddingLeft: "7px"
      }}>{props.coinName}</span>
  </div>
}

export default function AssetsList () {
  const { t } = useTranslation()
  
  const { id, blockchain } = useParams();

  const coins = useAppSelector(selectCoins)
  const dispatch = useAppDispatch()
  
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState(0)

  if (!coins) return null


  const header: Array<TableHeader> = [
    {content: t("address.assetsName"), clickable: true, icon: sort === 0 ? arrowImg : arrowTop, width: "188px", callback: () => {
      setPage(0)
      dispatch(reverseCoins())
      setSort(sort === 0 ? 1 : 0)
    }},
    {content: t("balance"), width: "195px",},
    {content: t("amountDollar"), width: "200px",},
    {content: t("address.nOTN"), width: "198px",},
    {content: t("address.incomeScore"), width: "198px",},
    {content: t("address.spendingScore"), width: "180px",},
  ]
  const body: Array<Array<TableValue>> = coins.slice(page * 4, page * 4 + 4).map(i => [
    {element: <CoinElm coinSrc={i.icon} coinName={blockchainTranslate(i.name)} />},
    {content: `${i.balance} ${i.symbol}`},
    {content: `${i.balanceUsd} USD`},
    {content: i.numberOfTransactions ? i.numberOfTransactions.toString() : "N/A"},
    i.incomeScore === 0 
      ? { content: "N/A"} : 
      ( i.incomeScore ? 
        {element: <ColorElm score={i.incomeScore} />} : 
        (i.calcIncomeScore ? loadingElement : { content: "查看分数", clickable: true, clickType: 0 } )
      ),
    i.spendingScore === 0 
      ? { content: "N/A"} :
      ( i.spendingScore ?
        {element: <ColorElm score={i.spendingScore} />} :
        (i.calcSpendingScore ? loadingElement : { content: "查看分数", clickable: true, clickType: 1 } )
      ),
  ])

  const clickButton = (key: number, clickType: number) => {
    if (!blockchain || !id) return
    dispatch(CalcAssetsScore({
      analysisType: clickType === 0 ? AnalysisType.Incoming : AnalysisType.Outgoing,
      blockchain,
      objectId: id,
      coin: coins[ page * 4 + key ].chainId || "MAIN",
      index: page * 4 + key
    }))
    dispatch(
      setCalcScoreStatus({
        index: page * 4 + key,
        type: clickType,
        status: true
      })
    )
  }

  return (
    <div className="assets-container">
      <div>
        <p className="assets-title">{t("address.assetsList")}</p>
        <Table th={header} td={body} clickCallback={clickButton} />
      </div>
      <Paging total={coins.length} page={page + 1} showCount={4} onChange={(page: number) => setPage(page - 1)} />
    </div>
  )
}