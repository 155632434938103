import { useTranslation } from "react-i18next"
import "./riskAssessment.css"
import securityIcon from "@/assets/security.png"
import warningIcon from "@/assets/warning.png"
import { useAppSelector } from "@/app/hooks"
import { selectEntityInfo } from "../entity/entitySlice"

export default function ValidationRules () {
  const { t } = useTranslation()

  const entityInfo = useAppSelector(selectEntityInfo);
  if (!entityInfo) return null
  
  const policies = entityInfo.policies
  
  return (
    <div className="risk-assessment-container">
      <p className="title">{t("entities.registerPolicy")}</p>

      <ul>
          <li>
            <p className="describe">{t("entities.policies.kyc")}</p>
            <img src={policies.isAskingPersonalInformation?securityIcon:warningIcon} alt="" />
            <span>{t(policies.isAskingPersonalInformation ? "entities.policies.kycY": "entities.policies.kycN")}</span>
          </li>
          <li>
            <p className="describe">{t("entities.policies.sanctions")}</p>
            <img src={policies.isApplyingSanctions?securityIcon:warningIcon} alt="" />
            <span>{t(policies.isApplyingSanctions ? "entities.policies.sanctionsY": "entities.policies.sanctionsN")}</span>
          </li>
          <li>
            <p className="describe">{t("entities.policies.dueDiligence")}</p>
            <img src={policies.hasDueDiligence?securityIcon:warningIcon} alt="" />
            <span>{t(policies.hasDueDiligence ? "entities.policies.dueDiligenceY": "entities.policies.dueDiligenceN")}</span>
          </li>
          <li>
            <p className="describe">{t("entities.policies.enhancedDueDiligence")}</p>
            <img src={policies.hasAdvancedDueDiligence?securityIcon:warningIcon} alt="" />
            <span>{t(policies.hasAdvancedDueDiligence ? "entities.policies.enhancedDueDiligenceY": "entities.policies.enhancedDueDiligenceN")}</span>
          </li>
          <li>
            <p className="describe">{t("entities.policies.complianceFunction")}</p>
            <img src={policies.hasComplianceOfficer?securityIcon:warningIcon} alt="" />
            <span>{t(policies.hasComplianceOfficer ? "entities.policies.complianceFunctionY": "entities.policies.complianceFunctionN")}</span>
          </li>
          
      </ul>

    </div>
  )
}