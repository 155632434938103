import { FormSelect } from "react-bootstrap"
import arrow from "@/assets/arrow.png"
import arrowTop from "@/assets/arrowTop.png"
import Paging from '@/features/common/paging'
import Table, { TableHeader, TableValue } from '@/features/common/table'
import { AnalysisType } from "./addressAPI"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "@/app/hooks"
import { blockchainTranslate, reverseAnalysisData, selectAnalysisError, selectAnalysisList, selectAssetNav, selectLoadScorigingAnalysis, showAssets } from "./addressSlice"
import LoadingIcon from "@/features/common/loading"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"


export default function RiskScoreList(props: { symbol: string, showAssets: boolean, onChangeType: Function, onChangeSelect?: Function }) {
  const { t } = useTranslation()

  const [dataType, setDataType] = useState(1)
  const [page, setPage] = useState(0)
  const [selectNavIndex, setNavIndex] = useState(0)
  const { blockchain } = useParams();

  const assetNav = useAppSelector(selectAssetNav)
  const analysisList = useAppSelector(selectAnalysisList);
  const loadAnalysisData = useAppSelector(selectLoadScorigingAnalysis);
  const loadAnalysisError = useAppSelector(selectAnalysisError);

  const dispatch = useDispatch()


  const changeType = (type: AnalysisType, status: number) => {
    if (loadAnalysisData) return

    setDataType(status)
    props.onChangeType(type)
  }

  const [sort, setSort] = useState(0)

  if (!analysisList) return null

  const header: Array<TableHeader> = [
    {
      content: t("address.riskRank.percentage"), clickable: true, icon: sort === 0 ? arrow : arrowTop, width: "98px", callback: () => {
        setPage(0)
        dispatch(reverseAnalysisData())
        setSort(sort === 0 ? 1 : 0)
      }
    },
    { content: t("address.riskRank.entityName"), width: "264px", },
    { content: t("address.type"), width: "135px", },
    { content: t("amountDollar"), width: "144px", },
    { content: t("amountNum"), width: "127px", },
    { content: t("address.riskRank.score"), width: "100px", },
  ]

  let body: Array<Array<TableValue>> = analysisList.slice(page * 10, page * 10 + 10).map(i => [
    { content: i.percentage.toString() + "%" },
    { content: i.name },
    { content: t("entityType." + i.type) },
    { content: i.amountUsd },
    { content: props.symbol == '-' ? 'N/A' : (props.showAssets ? (selectNavIndex > 0 && assetNav ? i.amount + " " + assetNav[selectNavIndex - 1].symbol : "N/A") : i.amount + " " + props.symbol) },
    { content: i.score.toString() },
  ])



  if (body.length === 0 || loadAnalysisData) {
    body = []
  }

  return (
    <>
      <div>
        <div className="title-wrap">
          <p className="title">{t("address.riskRank.title")}</p>
          <div>
            {props.showAssets ? <div className="select-cpt">
              <p>{t("address.assetsText")}</p>
              <FormSelect defaultValue={0} disabled={loadAnalysisData} onChange={(e) => {
                if (!props.onChangeSelect || !assetNav) return
                const index = e.target.selectedIndex
                const chainId = index === 0 ? "ALL" : (assetNav[index - 1].chainId || "MAIN")
                props.onChangeSelect(chainId, index)
                setNavIndex(index)
              }}>
                <option value={0}>{t("address.totalAssets")}</option>
                {assetNav && assetNav.map((i, k) => <option key={i.chainId} value={k + 1}>{blockchainTranslate(i.name)}</option>)}
              </FormSelect>
            </div> : null}

          </div>
        </div>
        <nav className="nav-list">
          <li className={dataType === 1 ? "active" : ''} onClick={() => changeType(AnalysisType.Incoming, 1)}>{t("address.riskRank.incomeRank")}</li>
          <li className={dataType === 2 ? "active" : ''} onClick={() => changeType(AnalysisType.Outgoing, 2)}>{t("address.riskRank.spendingRank")}</li>
        </nav>
        {loadAnalysisData ? <div className="riskscore-loadicon"><LoadingIcon size={20} color={"#4C6FFF"} /></div> : null}

        <Table th={header} td={body} emptyText={(loadAnalysisData || body.length > 0) ? null : (loadAnalysisError !== null ? loadAnalysisError : t("address.noTransfer"))} />
      </div>

      {loadAnalysisData ? null : <Paging total={analysisList.length} showCount={10} page={page + 1} onChange={(page: number) => setPage(page - 1)} />}
    </>
  )
}