import { useTranslation } from "react-i18next"
import Table, { TableHeader, TableValue } from '@/features/common/table'

import Paging from '@/features/common/paging'

import "./assetsList.css"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { blockchainTranslate, GetTransaction, selectLoadingTransaction, selectTransaction } from "./addressSlice"
import { useState } from "react"
import LoadingIcon from "@/features/common/loading"
import { useNavigate, useParams } from "react-router-dom"
import { CoinElm } from "./assetsList"

export default function NumberDeals () {
  const { t } = useTranslation()

  const [page, setPage] = useState(0)
  const { type } = useParams();

  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const { blockchain, id } = useParams();
  const transactions = useAppSelector(selectTransaction)
  const loading = useAppSelector(selectLoadingTransaction)

  if (!blockchain || !id ) return null
  
  const header: Array<TableHeader> = [
    {content: t("time"), width: "176px"},
    {content: t("transHash"), width: "393px",},
    {content: t("address.assetsName"), width: "160px",},
    {content: t("amountDollar"), width: "164px",},
    {content: t("amountNum"), width: "153px",},
    {content: t("address.type"), width: "113px",},
  ]
  

  let body: Array<Array<TableValue>> = (transactions.data || []).map(i => [
      {content: i.date},
      {content: i.hash, link: `/trading/${blockchain}/${i.hash}`},
      {mutiple: i.assetIcon.map((iconI, iconK) => <CoinElm key={`coinelm-${iconK}`} coinSrc={iconI} coinName={ blockchainTranslate(i.assetName[iconK]) } /> )},
      {mutiple: i.amountUsd},
      {mutiple: i.amount},
      {mutiple: i.type},
  ])

  if (loading) body = []

  const clickButton = (key: number, clickType: number) => {
    if (clickType === 1 && transactions.data) {
      const {hash} = transactions.data[key]
      navigate(`/trading/${blockchain}/${hash}`)
    }
  }

  const changePage = (page: number) => {
    dispatch(GetTransaction({
      blockchain: blockchain,
      address: id,
      offset: (page - 1) * 4,
      limit: 4,
      type: type === "1" ? "addresses":"wallets",
    }))
    setPage(page - 1)
  }

  return (
    <div className="container-item assets-container numberdeals-container">
      { loading ? <div className="container-loadicon"><LoadingIcon size={20} color={"#4C6FFF"} /></div> : null }

      <div className="numberdeals-table">
        <p className="assets-title">{t("address.nOT")}</p>
        <Table th={header} td={body} clickCallback={clickButton} mutipleTable={true} />
      </div>

      <Paging total={transactions.count} page={page + 1} showCount={4} onChange={(page: number) => changePage(page)} />
    </div>
  )
}