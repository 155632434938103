import { useTranslation } from "react-i18next"
import Table, { TableHeader, TableValue } from '@/features/common/table'

import arrowImg from "@/assets/arrow.png"
import arrowTop from "@/assets/arrowTop.png"
import Paging from '@/features/common/paging'

import "./assetsList.css"
import { CoinElm } from "./assetsList"
import { useAppDispatch, useAppSelector } from "@/app/hooks"
import { GetAddress, reverseWallets, selectWallets } from "../entity/entitySlice"
import { useState } from "react"
import { blockchainTranslate, hasWallet } from "./addressSlice"
import AddressWin from "@/views/Entity/Address"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router";


export default function WalletList () {
  const { t } = useTranslation()
  const { name, id } = useParams();
  const wallets = useAppSelector(selectWallets)

  const [page, setPage] = useState(0)
  const [sort, setSort] = useState(0)
  const [showAddress, setShowAddress] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  if (!wallets || !name || !id) return null

  const header: Array<TableHeader> = [
    {content: t("wallet.name"), clickable: true, icon: sort === 0 ? arrowImg : arrowTop, width: "659px", callback: () => {
      setPage(0)
      dispatch(reverseWallets())
      setSort(sort === 0 ? 1 : 0)
    }},
    {content: t("wallet.addressCount"), width: "336px",},
    {content: t("handleText"), width: "165px",},
  ]
  const body: Array<Array<TableValue>> = wallets.slice(page * 4, page * 4 + 4).map(i => [
    {element: <CoinElm coinSrc={`https://app.scorechain.com/images/cryptoicons/${i.blockchain}.svg`} coinName={blockchainTranslate(i.blockchain)} />},
    {content: i.addressCount.toString()},
    {content: t("moreInfo"), clickable: true, clickType: 0},
  ])

  if (body.length === 0) {
    body.push([
      {content: t("address.noAssets")},
      {content: ""},
      {content: ""},
    ])
  }

  const clickButton = (key: number, clickType: number) => {
    const selectData = wallets[page * 4 + key];

    if (hasWallet(selectData.blockchain)) {
      navigate(`/address/2/${selectData.blockchain}/${selectData.referenceAddress}`)
    } else {
      setShowAddress(true)
      dispatch(GetAddress({
        entityId: parseInt(id),
        blockchain: selectData.blockchain,
        offset: 0,
        limit: 10,
        entityName: name,
      }))
    }
  }

  return (
    <>
      <div className="assets-container">
        <div>
          <p className="assets-title">{t("entities.walletList")}</p>
          <Table th={header} td={body} clickCallback={clickButton} />
        </div>
        <Paging total={wallets.length} page={page + 1} showCount={4} onChange={(page: number) => setPage(page - 1)} />
      </div>
      { showAddress ? <AddressWin onClose={() => setShowAddress(false)} /> : null }
    </>
  )
}