import Address from "@/views/Address/Address";
import Trading from "@/views/Trading/Trading";
import Entity from "@/views/Entity/Entity";
import Alert from "@/views/Alert/Alert";
import Home from "@/views/Index/Index";

const authRoutes = [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    // type = 1 => 地址   2 => 钱包
    path: "/address/:type/:blockchain/:id",
    component: Address,
    exact: true,
  },
  {
    path: "/trading/:blockchain/:hash",
    component: Trading,
    exact: true,
  },
  {
    path: "/entities/:name/:id",
    component: Entity,
    exact: true,
  },
  {
    path: "/alert",
    component: Alert,
    exact: true,
  },
];

export default authRoutes;
