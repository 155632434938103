import "./accountInfo.css"
import { useTranslation } from "react-i18next"
import { ChoosMetaData } from "./addressAPI"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import questionIcon from "@/assets/question.png"

function GetIcon(prop: {color: string}) {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.833374 2.66667C0.833374 1.19391 3.81814 0 7.50004 0C11.1819 0 14.1667 1.19391 14.1667 2.66667V4C14.1667 5.47276 11.1819 6.66667 7.50004 6.66667C3.81814 6.66667 0.833374 5.47276 0.833374 4V2.66667ZM0.833374 6.33436V9.33333C0.833374 10.8061 3.81814 12 7.50004 12C11.1819 12 14.1667 10.8061 14.1667 9.33333V6.33436C13.737 6.65319 13.2355 6.91309 12.7093 7.12359C11.3095 7.68349 9.46719 8 7.50004 8C5.53289 8 3.69057 7.68349 2.29081 7.12359C1.76457 6.91309 1.26307 6.65319 0.833374 6.33436ZM14.1667 11.6677C13.737 11.9865 13.2355 12.2464 12.7093 12.4569C11.3095 13.0168 9.46719 13.3333 7.50004 13.3333C5.53289 13.3333 3.69057 13.0168 2.29081 12.4569C1.76457 12.2464 1.26307 11.9865 0.833374 11.6677V13.3333C0.833374 14.8061 3.81814 16 7.50004 16C11.1819 16 14.1667 14.8061 14.1667 13.3333V11.6677Z" fill={prop.color}/>
    </svg>
  )
}

export default function AccountInfo (props: ChoosMetaData) {
  const { t } = useTranslation()
  
  return (
    <div className="account-info-container">
      <div className="info-item">
        <div className="icon" style={{backgroundColor: "#D9ECFE"}}>
          <GetIcon color="#0072FB" />
        </div>
        <div className="info">
          <p className="describe">{t("balance")}</p>
          <p className="money balance-money">
            { props.balance ? <span>{props.balance === "null" ? "N/A" : `${props.balance} ${props.symbol && props.symbol}`}</span> : null }
            <span style={{color: "#0072FB"}}>{props.balanceUsd === "null" ? (props.balance ? null : "N/A") : props.balanceUsd + " USD"}</span>
          </p>
        </div>
      </div>

      <div className="info-item">
        <div className="icon" style={{backgroundColor: "#C2F5E9"}}>
          <GetIcon color="#3ED17F" />
        </div>
        <div className="info">
          <p className="describe">{t("address.income")}</p>
          <p className="money balance-money">
            { props.amountReceived ? <span>{props.amountReceived === "null" ? "N/A" : `${props.amountReceived} ${props.symbol && props.symbol}`}</span> : null }
            <span style={{color: "#3ED17F"}}>{props.amountReceivedUsd === "null" ? (props.amountReceived ? null : "N/A") : props.amountReceivedUsd + " USD"}</span>
          </p>
        </div>
      </div>

      <div className="info-item">
        <div className="icon" style={{backgroundColor: "#FBEDEB"}}>
          <GetIcon color="#D33C30" />
        </div>
        <div className="info">
          <p className="describe">{t("address.spending")}</p>
          <p className="money balance-money">
            { props.amountSent ? <span>{props.amountSent === "null" ? "N/A" : `${props.amountSent} ${props.symbol && props.symbol}`}</span> : null }
            <span style={{color: "#D33C30"}}>{props.amountSentUsd === "null" ? (props.amountSent ? null : "N/A") : props.amountSentUsd + " USD"}</span>
          </p>
        </div>
      </div>

      <div className="info-item">
        <div className="icon" style={{backgroundColor: "#FAF8BC"}}>
          <GetIcon color="#febe2d" />
        </div>
        <div className="info">
          <p className="describe">
            {t("address.nOTText")}
            {!props.numberOfTransactions ? <span className="info-question-icon">
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {t("address.error.morethan")}
                  </Tooltip>
                }
              >
                <img src={questionIcon} alt="" />
              </OverlayTrigger>
            </span> : null}
          </p>
          <p className="money"><span>{props.numberOfTransactions || "500000+"}</span></p>
        </div>
        
      </div>
      
      
    </div>
  )
}